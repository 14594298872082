export const environment = {
    production: true,
    defaultauth: 'awsCognito',
    jwtHostUrl: 'api.visiveonline.com',
    apiUrl: 'https://api.visiveonline.com/api/',
    webSocket: '165.227.238.98',
    webSocketAuth: 'https://api.visiveonline.com/broadcasting/auth',
    webSocketKey: '247965b59f6762bc6f6a',
    awsCognito: {
        userPoolId: "us-east-1_msE3qqWbj",
        appClientId: "5d9b8362eb20jn0e1oi4dfv9ij"
    }
};

