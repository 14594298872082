import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmartCscParams} from "../../../core/models/smartcsc";
import {Paginate} from "../../../core/models/paginate.model";

@Component({
    selector: 'app-ui-paginate',
    templateUrl: './paginate.component.html',
    styleUrls: ['./paginate.component.scss']
})
export class PaginateComponent implements OnInit {

    @Input() paginate: Paginate;

    @Output() loadPage = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    getPageArray()
    {
        let array = [];
        let length = this.paginate.total_records / this.paginate.per_page;

        for (let i = 0; i < length; i++) {
            array.push(i + 1)
        }

        return array;
    }

    page(page : number){
        this.loadPage.emit(page);
    }

}
