import {Injectable} from '@angular/core';

import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import {HttpHeaders} from '@angular/common/http';


import {User} from '../models/auth.models';

@Injectable({providedIn: 'root'})

export class AwsCognitoAuthService{

    user: User;

    public authtoken : string;

    constructor() {
    }

    public getJwtToken()
    {
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        var userPool = new CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                else
                {
                    this.authtoken = session.getAccessToken().getJwtToken();
                }
            });
        }
        return this.authtoken;
    }

    public isLoggedIn() : boolean
    {
        var isAuth = false;

        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        var userPool = new CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                isAuth = session.isValid();
            })
        }
        return isAuth;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        let authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        let userPool = new CognitoUserPool(poolData);

        let userData = { Username: email, Pool: userPool };

        var cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    // @todo update user info at top
                    this.authtoken = result.getAccessToken().getJwtToken();
                    resolve(result);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        // return new Promise((resolve, reject) => {
        //     reject("todo");
        // });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        // return new Promise((resolve, reject) => {
        //     reject("todo");
        // });
    }

    /**
     * Logout the user
     */
    logout() {
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };
        let userPool = new CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();
    }
}

